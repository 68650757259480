import {
  FiCheckSquare,
  FiCreditCard,
  FiExternalLink,
  FiFileText,
  FiFlag,
  FiGrid,
  FiRepeat,
  FiSave,
  FiUsers,
  FiZap
} from "react-icons/fi";
import { memoize } from "decko";
import DataConstants from "const/DataConstants";
import UiRoutes from "const/UiRoutes";

const {
  ACCOUNTS,
  TRANSACTIONS,
  DOCUMENTS,
  REPORTS,
  ARCHIVE,
  CONTACTS,
  DASHBOARD,
  AUDIT,
  TASKS,
  ACTIVITY
} = UiRoutes;

export default class Pages {
  @memoize
  static getCurrentRouteInfo(pathName) {
    const [businessId, route, ...restPath] = pathName.substr(1).split("/");

    return {
      businessId: businessId || null,
      route: route ? `/${route}` : null,
      restPath: [...restPath].filter((value) => value)
    };
  }

  @memoize
  static getPageData([, pageRoute]) {
    const { TO_REVIEW, TO_RECONCILE } = DataConstants.STATUSES;

    const pagesData = {
      DASHBOARD: {
        value: DASHBOARD,
        titleLangId: "dashboard",
        iconComponent: FiGrid
      },
      TASKS: {
        value: TASKS,
        titleLangId: "tasks",
        statsNotificationProp: "tasks.active.count",
        statsNotificationUrlParameters: { targetUser: "currentUser" },
        iconComponent: FiCheckSquare
      },
      ACCOUNTS: {
        value: ACCOUNTS,
        titleLangId: "bankAccounts",
        iconComponent: FiCreditCard
      },
      TRANSACTIONS: {
        value: TRANSACTIONS,
        titleLangId: "transactions",
        statsNotificationProp: `transactions.${TO_RECONCILE}.count`,
        statsNotificationUrlParameters: { status: TO_RECONCILE },
        iconComponent: FiRepeat
      },
      AUDIT: {
        value: AUDIT,
        titleLangId: "cleanUp",
        iconComponent: FiFlag,
        statsNotificationProp: "audit.total.count"
      },
      DOCUMENTS: {
        value: DOCUMENTS,
        titleLangId: "ocrAi",
        statsNotificationProp: `documents.${TO_REVIEW}.count`,
        statsNotificationUrlParameters: { status: TO_REVIEW },
        iconComponent: FiFileText
      },
      REPORTS: {
        value: REPORTS,
        titleLangId: "export",
        iconComponent: FiExternalLink
      },
      ARCHIVE: {
        value: ARCHIVE,
        titleLangId: "fileSharing",
        statsNotificationProp: "archive.notRead",
        iconComponent: FiSave
      },
      CONTACTS: {
        value: CONTACTS,
        titleLangId: "contacts",
        iconComponent: FiUsers
      },
      ACTIVITY: {
        value: ACTIVITY,
        titleLangId: "activity",
        iconComponent: FiZap
      }
    };

    return pageRoute ? Object.values(pagesData).find(({ value }) => value === pageRoute) : pagesData;
  }
}
